<header ngbNav class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <a class="navbar-brand d-none d-sm-block" routerLink="/">
            <img class="navbar-brand-regular" [src]="whiteLogo ? 'assets/img/logo-white.png' : 'assets/img/logo.png'" alt="brand-logo">
            <img class="navbar-brand-sticky" src="assets/img/logo.png" alt="sticky brand-logo">
        </a>
        <a class="navbar-brand d-block d-sm-none" routerLink="/">
            <img class="navbar-brand-regular" [src]="whiteLogo ? 'assets/img/logo-white-small.png' : 'assets/img/logo-small.png'" alt="brand-logo">
            <img class="navbar-brand-sticky" src="assets/img/logo-small.png" alt="sticky brand-logo">
        </a>
        <button class="navbar-toggler d-lg-none" type="button"  aria-label="Toggle navigation" (click)="toggleMenu()">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler   -->
            <button class="navbar-toggler d-lg-none" type="button" aria-label="Toggle navigation" (click)="toggleMenu()">
                <span class="navbar-toggler-icon"></span>
            </button>
             
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
                    <li ngbNavItem class="nav-item">
                        <a pageScroll ngbNavLink class="nav-link" routerLink="/" fragment="home" >Home</a>
                    </li>
                    <li ngbNavItem class="nav-item">
                        <a pageScroll ngbNavLink class="nav-link" routerLink="/" fragment="services">Services</a>
                    </li>
                    <li ngbNavItem class="nav-item">
                        <a pageScroll ngbNavLink class="nav-link" routerLink="/" fragment="features">Features</a>
                    </li>
                    
                    <li ngbDropdown class="nav-item dropdown">
                        <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">
                            Pages
                        </a>
                        <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <!-- <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Inner Pages<span class="badge badge-pill badge-warning ml-2">New</span></a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" routerLink="/subscribe">Newsletter</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" routerLink="/coming-soon">Coming Soon</a>
                                    </li>
                                </ul>
                            </li> -->
                            <li>
                                <a class="dropdown-item" routerLink="/blog">Blog</a>
                            </li>
                            <!-- <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Blog Details</a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" routerLink="/blog-details">Latest Blog Article</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Accounts<span class="badge badge-pill badge-warning ml-2">New</span></a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" routerLink="/login">Login</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" routerLink="/signup">Signup</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" routerLink="/reset">Reset Password</a>
                                    </li>
                                </ul>
                            </li> -->
                            <li>
                                <a class="dropdown-item" routerLink="/reviews">Reviews</a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/faq">FAQ</a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/contact">Contact</a>
                            </li>
                            
                        </ul>
                    </li>
                    <li ngbNavItem class="nav-item">
                        <a pageScroll ngbNavLink class="nav-link" routerLink="/" fragment="pricing">Pricing</a>
                    </li>
                    <li ngbNavItem class="nav-item">
                        <a pageScroll ngbNavLink class="nav-link" routerLink="/" fragment="contact">Contact</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>
<router-outlet></router-outlet>