import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from '@pages/home/home.component';
import { PricingPageComponent } from '@pages/pricing/pricing.component';
import { SubscribePageComponent } from '@pages/subscribe-page/subscribe-page.component';
import { ComingSoonComponent } from '@pages/coming-soon/coming-soon.component';
import { ErrorComponent } from '@pages/error/error.component';
import { BlogEntryPageComponent } from '@pages/blog-entry/blog-entry-page.component';
import { LoginPageComponent } from '@pages/accounts/login/login.component';
import { SignupPageComponent } from '@pages/accounts/signup/signup.component';
import { ResetPageComponent } from '@pages/accounts/reset/reset.component';
import { ReviewPageComponent } from '@pages/review-page/review-page.component';
import { FaqPageComponent } from '@pages/faq-page/faq-page.component';
import { ContactPageComponent } from '@pages/contact-page/contact-page.component';
import { PrivacyPolicyComponent } from '@pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '@pages/terms-conditions/terms-conditions.component';
import { BlogComponent } from '@pages/blog/blog.component';
import { AboutUsPageComponent } from '@pages/about-us/about-us.component';
import { SupportComponent } from '@pages/support/support.component';
import { ServicesComponent } from '@pages/services/services.component';

const routes: Routes = [
  {path: '', component: HomePageComponent},
  {path: 'pricing', component: PricingPageComponent},
  {path: 'subscribe', component: SubscribePageComponent},
  {path: 'coming-soon', component: ComingSoonComponent},
  {path: 'error', component: ErrorComponent},
  {path: 'blog/post/:id', component: BlogEntryPageComponent},
  {path: 'login', component: LoginPageComponent},
  {path: 'signup', component: SignupPageComponent},
  {path: 'reset', component: ResetPageComponent},
  {path: 'reviews', component: ReviewPageComponent},
  {path: 'faq', component: FaqPageComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms-conditions', component: TermsConditionsComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'about-us', component: AboutUsPageComponent},
  {path: 'support', component: SupportComponent},
  {path: 'services', component: ServicesComponent},
  {path: '**', component: ErrorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
