<div class="blog blog-right">
    <div class="main" *ngIf="blogEntry | async as entry" >
        <scroll-up></scroll-up>
        <app-navbar [whiteLogo]="true"></app-navbar>
        <app-breadcrumb title="Blog" currentPage="{{ entry.title }}"></app-breadcrumb>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <!-- Single Blog Details -->
                        <article class="single-blog-details">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <a routerLink="/"><img src="assets/img/blog-7.jpg" alt=""></a>
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content PymaTech-blog">
                                <!-- Meta Info -->
                                <div class="meta-info d-flex flex-wrap align-items-center py-2">
                                    <ul>
                                        <li class="d-inline-block p-2">By <a routerLink="/">{{entry.author}}</a></li>
                                        <li class="d-inline-block p-2"><a routerLink="/">{{ entry.postedOn }}</a></li>
                                        <li class="d-inline-block p-2"><a routerLink="/">2 Comments</a></li>
                                    </ul>
                                    <!-- Blog Share -->
                                    <div class="blog-share ml-auto d-none d-sm-block">
                                        <!-- Social Icons -->
                                        <div class="social-icons d-flex justify-content-center">
                                            <a class="bg-white facebook" routerLink="/">
                                                <i class="fab fa-facebook-f"></i>
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                            <a class="bg-white twitter" routerLink="/">
                                                <i class="fab fa-twitter"></i>
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                            <a class="bg-white instagram" routerLink="/">
                                                <i class="fab fa-instagram"></i>
                                                <i class="fab fa-instagram"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Blog Details -->
                                <div class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a routerLink="/">{{entry.title}}</a></h3>
                                    <p class="d-none d-sm-block">{{ entry.markup }}</p>
                                    <p class="d-block d-sm-none">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage.</p>
                                    <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                                        <p class="d-none d-sm-block">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature.</p>
                                        <p class="d-block d-sm-none">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                                    </blockquote>
                                    <p class="d-none d-sm-block">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                                    <p class="d-block d-sm-none">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33.</p>
                                </div>
                            </div>
                            <!-- Blog Comments -->
                            <div class="blog-comments">
                                <!-- Admin -->
                                <div class="admin media py-4">
                                    <!-- Admin Thumb -->
                                    <div class="admin-thumb avatar-lg">
                                        <img class="rounded-circle" src="assets/img/avatar-1.png" alt="">
                                    </div>
                                    <!-- Admin Content -->
                                    <div class="admin-content media-body pl-3 pl-sm-4">
                                        <h4 class="admin-name mb-2"><a routerLink="/">Anna Swford</a></h4>
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                                    </div>
                                </div>
                                <!-- Comments -->
                                <div class="comments my-5">
                                    <!-- Comments Title -->
                                    <h3 class="comments-title text-primary text-uppercase text-right mb-3">Recent Comments</h3>
                                    <!-- Single Comments -->
                                    <div class="single-comments media p-3 p-lg-4">
                                        <!-- Comments Thumb -->
                                        <div class="comments-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar-2.png" alt="">
                                        </div>
                                        <!-- Comments Content -->
                                        <div class="comments-content media-body pl-3">
                                            <h5 class="d-flex mb-2">
                                                <a routerLink="/">Jonayed Hasan</a>
                                                <a routerLink="/" class="ml-auto">Reply</a>
                                            </h5>
                                            <p class="d-none d-lg-block">Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                                            <p class="d-block d-lg-none">Many desktop publishing packages and web page editors now use.</p>
                                        </div>
                                    </div>
                                    <!-- Single Comments -->
                                    <div class="single-comments media p-3 p-lg-4">
                                        <!-- Comments Thumb -->
                                        <div class="comments-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar-3.png" alt="">
                                        </div>
                                        <!-- Comments Content -->
                                        <div class="comments-content media-body pl-3">
                                            <h5 class="d-flex mb-2">
                                                <a routerLink="/">Jassica William</a>
                                                <a routerLink="/" class="ml-auto">Reply</a>
                                            </h5>
                                            <p class="d-none d-lg-block">But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.</p>
                                            <p class="d-block d-lg-none">But I must explain to you how all this mistaken idea.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Blog Contact -->
                            <div class="blog-contact my-5">
                                <!-- Contact Title -->
                                <h3 class="comments-title text-primary text-uppercase text-right mb-3">Post your Comments</h3>
                                <!-- Comment Box -->
                                <div class="contact-box comment-box">
                                    <form method="POST" action="#">
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="name" placeholder="Name" required="required">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <input type="email" class="form-control" name="email" placeholder="Email" required="required">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <textarea class="form-control" name="message" placeholder="Message" required="required"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-primary btn-bordered" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="col-12 col-lg-3">
                        <app-blog-sidebar></app-blog-sidebar>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-two></app-footer-two>
    </div>
</div>