<div class="inner inner-pages">
    <div class="main">
        <section id="home" class="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
            <div class="container h-100">
                <div class="row align-items-center justify-content-center h-100">
                    <div class="col-12 col-md-7">
                        <div class="welcome-intro error-area text-center">
                            <h1 class="text-white">404</h1>
                            <p class="text-white my-4">The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                            <a href="/" class="btn PymaTech-btn text-uppercase">Go to Homepage</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>