<div class="single-blog res-margin">
    <!-- Blog Thumb -->
    <app-blog-thumbnail [routerLink]="blogEntry.url" [imageSrc]="blogEntry.thumbnailImage" ></app-blog-thumbnail>
    <!-- Blog Content -->
    <div class="blog-content p-4">
        <!-- Meta Info -->
        <ul class="meta-info d-flex justify-content-between">
            <li>By <a routerLink="" (click)="!!showBlogsByAuthor(blogEntry.author)" >{{ blogEntry.author }}</a></li>
            <li>{{ this.formatDate(blogEntry.postedOn) }}</li>
        </ul>
        <!-- Blog Title -->
        <h3 class="blog-title my-3"><a [routerLink]="blogEntry.url">{{ blogEntry.title }}</a></h3>
        <p>{{ blogEntry.markup }}</p>
        <a [routerLink]="blogEntry.url" class="blog-btn mt-3">Read More</a>
    </div>
</div>