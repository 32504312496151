import { Component, OnInit } from '@angular/core';
import { BlogCategory } from 'src/app/models/BlogCategory';
import { BlogService } from 'src/app/services/blog-service';

@Component({
  selector: 'app-blog-categories',
  templateUrl: './blog-categories.component.html',
  styleUrls: ['./blog-categories.component.css']
})
export class BlogCategoriesComponent implements OnInit {

  constructor(private blogService:BlogService) { }

  ngOnInit(): void {
  }

  getBlogCategories(topN:number) {
    return this.blogService.getCategories(topN);
  }
}
