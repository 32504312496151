import { BlogEntry } from '../models/BlogEntry';
import { Injectable } from '@angular/core';
import { BlogCategory } from '../models/BlogCategory';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlogService {

    categories: BlogCategory[] = [
        { categoryName: "Web Design", count:14 },
        { categoryName: "App Landing", count:32 },
        { categoryName: "Wordpress", count:27 },
        { categoryName: "UI Design", count:18 },
        { categoryName: "Bootstrap", count:15 },
        { categoryName: "Web Template", count:29 }
    ]

    posts: BlogEntry[] = [
        {
            title : 'How to promote your product with this awesome landing page',
            thumbnailImage: "assets/img/blog-1.jpg",
            url: "/blog/post/1",
            tags: [],
            author : 'John Doe',
            postedOn : new Date(),
            markup : 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using'
        },
        {
            title : 'Managing your clients remotely',
            thumbnailImage: "assets/img/blog-1.jpg",
            url: "/blog/post/2",
            tags: [],
            author : 'Jessica Williams',
            postedOn : new Date(),
            markup : 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it'
        },
        {
            title : 'Which cloud provider to use?',
            thumbnailImage: "assets/img/blog-1.jpg",
            url: "/blog/post/3",
            tags: [],
            author : 'David Jones',
            postedOn : new Date(),
            markup : 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it'
        },
        {
            title : 'Setting up OpenVPN',
            thumbnailImage: "assets/img/blog-1.jpg",
            url: "/blog/post/4",
            tags: [],
            author : 'Danny McBride',
            postedOn : new Date(),
            markup : 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it'
        },
        {
            title : 'International Sales',
            thumbnailImage: "assets/img/blog-1.jpg",
            url: "/blog/post/5",
            tags: [],
            author : 'Jessica Williams',
            postedOn : new Date(),
            markup : 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it'
        },
        {
            title : 'Test-driven Development',
            thumbnailImage: "assets/img/blog-1.jpg",
            url: "/blog/post/6",
            tags: [],
            author : 'Roger Moore',
            postedOn : new Date(),
            markup : 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it'
        },
        {
            title : 'Security always comes first',
            thumbnailImage: "assets/img/blog-1.jpg",
            url: "/blog/post/7",
            tags: [],
            author : 'Emma English',
            postedOn : new Date(),
            markup : 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it'
        }
    ];

    getBlogEntries(topN: number): BlogEntry[] {
        return this.posts.slice(0, topN);
    }

    getCategories(topN: number): BlogCategory[] {
        return this.categories.slice(0, topN);
    }

    async getBlogEntryById(id: number) {
        return this.posts.find((value,index) => index+1 == id);
    }
    // async getBlogEntries(): Promise<BlogEntry[]> {
    //     return await this.blogClient.getCollection('posts').then(
    //         posts => {
    //             //
    //             return posts;
    //         }
    //     );
    // }
}
