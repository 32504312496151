import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-card',
  templateUrl: './faq-card.component.html',
  styleUrls: ['./faq-card.component.scss']
})
export class FaqCardComponent implements OnInit {

  @Input()
  show:Boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    this.show = !this.show;
  }
}
