<section id="review" class="review-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                        <i class="far fa-lightbulb text-primary mr-1"></i>
                        <span class="text-primary">Customer's</span>
                        Reviews
                    </span>
                    <h2 class="text-capitalize">What our customers are saying</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Single Review -->
                <div class="single-review card">
                    <!-- Card Top -->
                    <div class="card-top p-4">
                        <div class="review-icon">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                        </div>
                        <h4 class="text-primary mt-4 mb-3">Excellent service &amp; support!!</h4>
                        <!-- Review Text -->
                        <div class="review-text">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis nam id facilis, provident doloremque placeat eveniet molestias laboriosam. Optio, esse.</p>
                        </div>
                        <!-- Quotation Icon -->
                        <div class="quot-icon">
                            <img class="avatar-md" src="assets/img/quote.png" alt="">
                        </div>
                    </div>
                    <!-- Reviewer -->
                    <div class="reviewer media bg-gray p-4">
                        <!-- Reviewer Thumb -->
                        <div class="reviewer-thumb">
                            <img class="avatar-lg radius-100" src="assets/img/avatar-1.png" alt="">
                        </div>
                        <!-- Reviewer Media -->
                        <div class="reviewer-meta media-body align-self-center ml-4">
                            <h5 class="reviewer-name color-primary mb-2">Junaid Hasan</h5>
                            <h6 class="text-secondary fw-6">CEO, Themeland</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Single Review -->
                <div class="single-review card">
                    <!-- Card Top -->
                    <div class="card-top p-4">
                        <div class="review-icon">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star-half-alt text-warning"></i>
                        </div>
                        <h4 class="text-primary mt-4 mb-3">Nice work! Keep it up</h4>
                        <!-- Review Text -->
                        <div class="review-text">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis nam id facilis, provident doloremque placeat eveniet molestias laboriosam. Optio, esse.</p>
                        </div>
                        <!-- Quotation Icon -->
                        <div class="quot-icon">
                            <img class="avatar-md" src="assets/img/quote.png" alt="">
                        </div>
                    </div>
                    <!-- Reviewer -->
                    <div class="reviewer media bg-gray p-4">
                        <!-- Reviewer Thumb -->
                        <div class="reviewer-thumb">
                            <img class="avatar-lg radius-100" src="assets/img/avatar-2.png" alt="">
                        </div>
                        <!-- Reviewer Media -->
                        <div class="reviewer-meta media-body align-self-center ml-4">
                            <h5 class="reviewer-name color-primary mb-2">Junaid Hasan</h5>
                            <h6 class="text-secondary fw-6">CEO, Themeland</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Review -->
                <div class="single-review card">
                    <!-- Card Top -->
                    <div class="card-top p-4">
                        <div class="review-icon">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                        </div>
                        <h4 class="text-primary mt-4 mb-3">Great support!!</h4>
                        <!-- Review Text -->
                        <div class="review-text">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis nam id facilis, provident doloremque placeat eveniet molestias laboriosam. Optio, esse.</p>
                        </div>
                        <!-- Quotation Icon -->
                        <div class="quot-icon">
                            <img class="avatar-md" src="assets/img/quote.png" alt="">
                        </div>
                    </div>
                    <!-- Reviewer -->
                    <div class="reviewer media bg-gray p-4">
                        <!-- Reviewer Thumb -->
                        <div class="reviewer-thumb">
                            <img class="avatar-lg radius-100" src="assets/img/avatar-3.png" alt="">
                        </div>
                        <!-- Reviewer Media -->
                        <div class="reviewer-meta media-body align-self-center ml-4">
                            <h5 class="reviewer-name color-primary mb-2">Junaid Hasan</h5>
                            <h6 class="text-secondary fw-6">CEO, Themeland</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>