<section id="contact" class="contact-area bg-gray ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Contact Us</h2>
                    <p class="d-none d-sm-block mt-4">Stayed tuned to the latest news from PymaTech or get in touch to let us know how we can assist you further.</p>
                    <p class="d-block d-sm-none mt-4">We'd love to hear from you.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-12 col-md-5">
                <!-- Contact Us -->
                <div class="contact-us">
                    <p class="mb-3">Discover more about PymaTech and the services we offer.</p>
                    <ul>
                        <li class="py-2">
                            <a class="media" routerLink="/">
                                <div class="social-icon mr-3">
                                    <fa-icon icon="home"></fa-icon>
                                </div>
                                <span class="media-body align-self-center">Greenville, PA 16125</span>
                            </a>
                        </li>
                        <li class="py-2">
                            <a class="media" routerLink="/">
                                <div class="social-icon mr-3">
                                    <fa-icon icon="phone-alt"></fa-icon>
                                </div>
                                <span class="media-body align-self-center">+1 724-418-4246</span>
                            </a>
                        </li>
                        <li class="py-2">
                            <a class="media" routerLink="/">
                                <div class="social-icon mr-3">
                                    <fa-icon icon="envelope"></fa-icon>
                                </div>
                                <span class="media-body align-self-center">contact@pymatech.com</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 pt-4 pt-md-0">
                <!-- Contact Box -->
                <div class="contact-box text-center">
                    <!-- Contact Form -->
                    <form id="contact-form" method="POST" action="assets/php/mail.php">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Name" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" name="email" placeholder="Email" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="subject" placeholder="Subject" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control" name="message" placeholder="Message" required="required"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary btn-lg btn-block mt-3"><span class="text-white pr-3"><i class="fas fa-paper-plane"></i></span>Send Message</button>
                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- 
<section id="contact" class="contact-area bg-gray ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-8">
                <div class="section-heading">
                    <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                        <i class="far fa-lightbulb text-primary mr-1"></i>
                        <span class="text-primary">Stay</span>
                        Tuned
                    </span>
                    <h2>Don't hesitate to contact us</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-12 col-md-5">
                <div class="contact-us">
                    <p class="mb-3">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                    <ul>
                        <li class="py-2">
                            <a class="media" routerLink="/">
                                <div class="social-icon mr-3">
                                    <i class="fas fa-home"></i>
                                </div>
                                <span class="media-body align-self-center">Greenville, PA 16125</span>
                            </a>
                        </li>
                        <li class="py-2">
                            <a class="media" routerLink="/">
                                <div class="social-icon mr-3">
                                    <i class="fas fa-phone-alt"></i>
                                </div>
                                <span class="media-body align-self-center">+1 724-418-4246</span>
                            </a>
                        </li>
                        <li class="py-2">
                            <a class="media" routerLink="/">
                                <div class="social-icon mr-3">
                                    <i class="fas fa-envelope"></i>
                                </div>
                                <span class="media-body align-self-center">contact@pymatech.com</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 pt-4 pt-md-0">
                <div class="contact-box text-center">
                    <form id="contact-form" method="POST" action="assets/php/mail.php">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Name" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" name="email" placeholder="Email" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="subject" placeholder="Subject" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control" name="message" placeholder="Message" required="required"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary btn-lg btn-block mt-3"><span class="text-white pr-3"><i class="fas fa-paper-plane"></i></span>Send Message</button>
                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<ng-container *ngIf="showMap">
    <section class="section map-area">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11969.890021694058!2d-80.37699915!3d41.40726065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1589852843495!5m2!1sen!2sus" width="100" height="100" style="border:0;" allowfullscreen="" frameborder="0" aria-hidden="false" tabindex="0"></iframe>
    </section>
</ng-container>