<section id="pricing" class="section price-plan-area bg-gray overflow-hidden ptb_100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-7">
        <!-- Section Heading -->
        <div class="section-heading text-center">
          <h2>Unlock the power of the cloud</h2>
          <p class="d-none d-sm-block mt-4">
           Choose the plan that meets your needs. Upgrading to a higher plan will take effect immediately.
          </p>
          <p class="d-block d-sm-none mt-4">Choose the plan that meets your needs. Upgrading to a higher plan will take effect immediately.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-sm-10 col-lg-8">
        <div class="row price-plan-wrapper">
          <div class="col-12 col-md-6">
            <!-- Single Price Plan -->
            <div class="single-price-plan text-center p-5 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
              <!-- Plan Thumb -->
              <div class="plan-thumb">
                <img class="avatar-lg" src="assets/img/price_basic.png" alt="" />
              </div>
              <!-- Plan Title -->
              <div class="plan-title my-2 my-sm-3">
                <h3 class="text-uppercase">Basic</h3>
              </div>
              <!-- Plan Price -->
              <div class="plan-price pb-2 pb-sm-3">
                <h1 class="color-primary"><small class="fw-7">$</small>49</h1>
              </div>
              <!-- Plan Description -->
              <div class="plan-description">
                <ul class="plan-features">
                  <li class="border-top py-3">5GB Linux Web Space</li>
                  <li class="border-top py-3">5 MySQL Databases</li>
                  <li class="border-top py-3">24/7 Tech Support</li>
                  <li class="border-top border-bottom py-3">Daily Backups</li>
                </ul>
              </div>
              <!-- Plan Button -->
              <div class="plan-button">
                <a routerLink="/" class="btn btn-primary mt-4">Sign Up</a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-4 mt-md-0">
            <!-- Single Price Plan -->
            <div class="single-price-plan text-center p-5 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
              <!-- Plan Thumb -->
              <div class="plan-thumb">
                <img class="avatar-lg" src="assets/img/price_premium.png" alt="" />
              </div>
              <!-- Plan Title -->
              <div class="plan-title my-2 my-sm-3">
                <h3 class="text-uppercase">Pro</h3>
              </div>
              <!-- Plan Price -->
              <div class="plan-price pb-2 pb-sm-3">
                <h1 class="color-primary"><small class="fw-7">$</small>129</h1>
              </div>
              <!-- Plan Description -->
              <div class="plan-description">
                <ul class="plan-features">
                  <li class="border-top py-3">10GB Linux Web Space</li>
                  <li class="border-top py-3">50 MySQL Databases</li>
                  <li class="border-top py-3">Unlimited Email</li>
                  <li class="border-top border-bottom py-3">Daily Backups</li>
                </ul>
              </div>
              <!-- Plan Button -->
              <div class="plan-button">
                <a routerLink="/" class="btn btn-primary mt-4">Sign Up</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center pt-5">
      <p class="text-body pt-4 fw-5">Not sure what to choose? <a routerLink="/contact">Contact Us</a></p>
    </div>
  </div>
</section>
