import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from '@components/navbar/navbar.component';
import { WelcomeOneComponent } from '@pages/welcome/welcome-one/welcome-one.component';
import { CounterComponent } from '@components/counter/counter.component';
import { FeaturesComponent } from '@widgets/features/features.component';
import { ServicesComponent } from '@pages/services/services.component';
import { DiscoverOneComponent } from '@pages/discover/discover-one/discover-one.component';
import { WorkComponent } from '@widgets/work/work.component';
import { ScreenshotOneComponent } from '@widgets/screenshots/screenshot-one/screenshot-one.component';
import { PricingWidgetComponent } from '@widgets/pricing/pricing-widget.component';
import { FaqOneComponent } from '@widgets/faq/faq-one/faq-one.component';
import { TeamComponent } from '@widgets/team/team.component';
import { SubscribeComponent } from '@widgets/subscribe/subscribe.component';
import { ContactComponent } from '@widgets/contact/contact.component';
import { FooterOneComponent } from '@components/footer/footer-one/footer-one.component';
import { FooterTwoComponent } from '@components/footer/footer-two/footer-two.component';
import { ScrollingModule } from '@pymatech/pymatech-common-ui';
import { WelcomeTwoComponent } from '@pages/welcome/welcome-two/welcome-two.component';
import { DiscoverTwoComponent } from '@pages/discover/discover-two/discover-two.component';
import { ScreenshotTwoComponent } from '@widgets/screenshots/screenshot-two/screenshot-two.component';
import { ReviewOneComponent } from '@widgets/reviews/review-one/review-one.component';
import { ReviewTwoComponent } from '@widgets/reviews/review-two/review-two.component';
import { FaqTwoComponent } from '@widgets/faq/faq-two/faq-two.component';
import { WelcomeThreeComponent } from '@pages/welcome/welcome-three/welcome-three.component';
import { WelcomeFourComponent } from '@pages/welcome/welcome-four/welcome-four.component';
import { WelcomeFiveComponent } from '@pages/welcome/welcome-five/welcome-five.component';
import { WelcomeSixComponent } from '@pages/welcome/welcome-six/welcome-six.component';
import { DiscoverThreeComponent } from '@pages/discover/discover-three/discover-three.component';
import { ReviewThreeComponent } from '@widgets/reviews/review-three/review-three.component';
import { DiscoverFourComponent } from '@pages/discover/discover-four/discover-four.component';
import { DiscoverFiveComponent } from '@pages/discover/discover-five/discover-five.component';
import { DiscoverSixComponent } from '@pages/discover/discover-six/discover-six.component';
import { BrandComponent } from '@widgets/brand/brand.component';
import { BlogComponent } from '@pages/blog/blog.component';
import { BlogEntryPageComponent } from '@pages/blog-entry/blog-entry-page.component';
import { ComingSoonComponent } from '@pages/coming-soon/coming-soon.component';
import { ErrorComponent } from '@pages/error/error.component';
import { LoginPageComponent } from '@pages/accounts/login/login.component';
import { SignupPageComponent } from '@pages/accounts/signup/signup.component';
import { ResetPageComponent } from '@pages/accounts/reset/reset.component';
import { FaqThreeComponent } from '@widgets/faq/faq-three/faq-three.component';
import { ReviewPageComponent } from '@pages/review-page/review-page.component';
import { SubscribePageComponent } from '@pages/subscribe-page/subscribe-page.component';
import { FaqPageComponent } from '@pages/faq-page/faq-page.component';
import { ContactPageComponent } from '@pages/contact-page/contact-page.component';
import { HomePageComponent } from '@pages/home/home.component';
import { BlogEntryComponent } from '@components/blog/blog-entry/blog-entry.component';
import { BlogSidebarComponent } from '@components/blog/blog-sidebar/blog-sidebar.component';
import { BlogCategoriesComponent } from '@components/blog/blog-categories/blog-categories.component';
import { PopularPostsComponent } from '@components/blog/popular-posts/popular-posts.component';
import { PopularTagsComponent } from '@components/blog/popular-tags/popular-tags.component';
import { TermsConditionsComponent } from '@pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from '@pages/privacy-policy/privacy-policy.component';
import { AboutUsPageComponent } from '@pages/about-us/about-us.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { BdtComponent } from '@components/bdt/bdt.component';
import { PricingPageComponent } from '@pages/pricing/pricing.component';
import { AboutUsWidgetComponent } from '@widgets/about-us-widget/about-us-widget.component';
import { SupportComponent } from '@pages/support/support.component';
import { ServicesWidgetComponent } from '@widgets/services-widget/services-widget.component';
import { SupportWidgetComponent } from '@widgets/support-widget/support-widget.component';
import { CloudProvidersComponent } from '@widgets/cloud-providers/cloud-providers.component';
import { BlogService } from './services/blog-service';
import { CountUpModule } from 'ngx-countup';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHome, faPhoneAlt, faEnvelope, faChevronRight, faAngleRight, faAngleDown, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { BlogThumbnailComponent } from '@components/blog/blog-thumbnail/blog-thumbnail.component';
import { RouterModule } from '@angular/router';
import { FaqCardComponent } from './widgets/faq/faq-card/faq-card.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    WelcomeOneComponent,
    CounterComponent,
    FeaturesComponent,
    ServicesComponent,
    DiscoverOneComponent,
    WorkComponent,
    ScreenshotOneComponent,
    PricingWidgetComponent,
    PricingPageComponent,
    FaqOneComponent,
    TeamComponent,
    SubscribeComponent,
    ContactComponent,
    FooterOneComponent,
    FooterTwoComponent,
    WelcomeTwoComponent,
    DiscoverTwoComponent,
    ScreenshotTwoComponent,
    ReviewOneComponent,
    ReviewTwoComponent,
    FaqTwoComponent,
    WelcomeThreeComponent,
    WelcomeFourComponent,
    WelcomeFiveComponent,
    WelcomeSixComponent,
    DiscoverThreeComponent,
    ReviewThreeComponent,
    DiscoverFourComponent,
    DiscoverFiveComponent,
    DiscoverSixComponent,
    BrandComponent,
    BlogComponent,
    BlogEntryPageComponent,
    ComingSoonComponent,
    ErrorComponent,
    LoginPageComponent,
    SignupPageComponent,
    ResetPageComponent,
    FaqThreeComponent,
    BreadcrumbComponent,
    ReviewPageComponent,
    SubscribePageComponent,
    FaqPageComponent,
    ContactPageComponent,
    HomePageComponent,
    BlogEntryComponent,
    BlogSidebarComponent,
    BlogCategoriesComponent,
    PopularPostsComponent,
    PopularTagsComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    AboutUsPageComponent,
    BdtComponent,
    AboutUsWidgetComponent,
    SupportComponent,
    ServicesWidgetComponent,
    SupportWidgetComponent,
    CloudProvidersComponent,
    BlogThumbnailComponent,
    FaqCardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    CountUpModule,
    ScrollToModule.forRoot(),
    NgxPageScrollModule,
    NgxPageScrollCoreModule,
    NgbModule,
    NgbNavModule,
    CarouselModule,
    ScrollingModule,
    FontAwesomeModule
  ],
  providers: [BlogService, NgbModule, NgbNavModule],
  bootstrap: [AppComponent]
})
export class AppModule { 

  constructor(library: FaIconLibrary) {
    library.addIcons(faHome, faPhoneAlt, faEnvelope, faChevronRight, faAngleRight, faAngleDown, faAngleDoubleRight);
  }
}
