<div class="main">
    <scroll-up></scroll-up>
    <app-navbar [whiteLogo]="true"></app-navbar>
    <app-welcome-one></app-welcome-one>
    <app-counter></app-counter>
    <app-services-widget></app-services-widget>
    <app-features></app-features>
    <app-discover-three></app-discover-three>
    <app-work></app-work>
    <!-- <app-screenshot-two></app-screenshot-two> -->
    <app-review-one></app-review-one>
    <app-pricing-widget></app-pricing-widget>
    <app-faq-two></app-faq-two>
    <app-team></app-team>
    <app-subscribe></app-subscribe>
    <app-contact [showMap]="false"></app-contact>
    <app-footer-one></app-footer-one>
</div>