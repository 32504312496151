<div class="card border-top-0 border-left-0 border-right-0 border-bottom">
    <div class="card-header bg-inherit border-0 p-0">
        <h2 class="mb-0">
            <div>
                <ng-content selector="[header]"></ng-content>
            </div>
                
            <button class="btn px-0 py-3" type="button" (click)="toggle()">
                <fa-icon icon="angle-down"></fa-icon>    
            </button>
        </h2>
    </div>
    <div>
        <ng-container *ngIf=show>
            <div class="card-body px-0 py-3">
                <ng-content selector="[content]"></ng-content>
            </div>
        </ng-container>
    </div>
</div>
