<div class="height-emulator d-none d-lg-block"></div>
<footer class="footer-area footer-fixed">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Logo -->
                        <a class="navbar-brand" routerLink="/">
                            <img class="logo" src="assets/img/logo-small.png" alt="">
                        </a>
                        <ul class="mt-2 mb-3 footer-widget__list">
                            <li>Greenville, PA 16125</li>
                            <li><a href="mailto:contact@pymatech.com" class="hover-style-link">contact@pymatech.com</a></li>
                            <li><a href="tel:7244184246" class="hover-style-link text-black font-weight--bold">724-418-4246</a></li>
                            <li><a href="https://www.pymatech.com/" class="hover-style-link text-color-primary">www.pymatech.com</a></li>
                        </ul>
                        <!-- Social Icons -->
                        <div class="social-icons d-flex">
                            <a class="linkedin" href="https://linkedin.com" target="_blank" aria-label="Linkedin">
                                <i class="fab fa-linkedin"></i>
                                <!-- <i class="fab fa-linkedin"></i> -->
                            </a>
                            <a class="facebook" href="https://facebook.com/pymatech" target="_blank" aria-label="Facebook">
                                <i class="fab fa-facebook-f"></i>
                                <!-- <i class="fab fa-facebook-f"></i> -->
                            </a>
                            <a class="twitter" href="https://twitter.com" target="_blank" aria-label="Twitter">
                                <i class="fab fa-twitter"></i>
                                <!-- <i class="fab fa-twitter"></i> -->
                            </a>
                            <a class="instagram" href="https://instagram.com" target="_blank" aria-label="Instagram">
                                <i class="fab fa-instagram"></i>
                                <!-- <i class="fab fa-instagram"></i> -->
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title mb-2">Useful Links</h3>
                        <ul>
                            <li class="py-2"><a routerLink="/">Home</a></li>
                            <li class="py-2"><a routerLink="/about-us">About Us</a></li>
                            <li class="py-2"><a routerLink="/services">Services</a></li>
                            <li class="py-2"><a routerLink="/blog">Blog</a></li>
                            <li class="py-2"><a routerLink="/contact-us">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title mb-2">Product Help</h3>
                        <ul>
                            <li class="py-2"><a routerLink="/faq">FAQ</a></li>
                            <li class="py-2"><a routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li class="py-2"><a routerLink="/support">Support</a></li>
                            <li class="py-2"><a routerLink="/terms-conditions">Terms &amp; Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left">&copy; 2020 <a href="https://www.pymatech.com/">PymaTech</a> All rights reserved.</div>
                        <!-- Copyright Right -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>