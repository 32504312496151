import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlogEntry } from 'src/app/models/BlogEntry';
import { BlogService } from 'src/app/services/blog-service';


@Component({
  selector: 'app-blog-entry-page',
  templateUrl: './blog-entry-page.component.html',
  styleUrls: ['./blog-entry-page.component.css']
})
export class BlogEntryPageComponent implements OnInit {

  private id: number;
  public blogEntry: BehaviorSubject<BlogEntry> = new BehaviorSubject(null);

  constructor(private activatedroute:ActivatedRoute, private blogService: BlogService) {
    this.activatedroute.paramMap.subscribe(params => { 
      this.id = Number.parseInt(params.get('id'));
      blogService.getBlogEntryById(this.id).then(entry => {
        this.blogEntry.next(entry);
      });
    });
  }

  ngOnInit(): void {
  }

}
