<!-- Category Widget -->
<div class="accordions widget catagory-widget" id="cat-accordion">
  <div class="single-accordion blog-accordion">
    <h5>
      <a role="button" class="collapse show text-uppercase d-block p-3" data-toggle="collapse" routerLink="/" fragment="accordion1"
        >Categories
      </a>
    </h5>
    <!-- Category Widget Content -->
    <div id="accordion1" class="accordion-content widget-content collapse show" data-parent="#cat-accordion">
      <!-- Category Widget Items -->
      <ul class="widget-items">
        <ng-container *ngFor="let blogCategory of getBlogCategories(10)">
          <li>
            <a routerLink="/" class="d-flex active p-3"><span>{{ blogCategory.categoryName }}</span><span class="ml-auto">({{ blogCategory.count }})</span></a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
