import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ScrollMonitor } from '@pymatech/pymatech-common-ui';

@Component({
  selector: 'app-navbar[whiteLogo]',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavBarComponent implements OnInit {

  private scrollMonitor: ScrollMonitor;
  private zero = 0;

  isMenuOpen = false;

  @Input()
  whiteLogo: boolean;

  constructor(scrollMonitor: ScrollMonitor, private router: Router) {
    this.scrollMonitor = scrollMonitor;
   }

  ngOnInit(): void {

    this.scrollMonitor
      .getScrollAsStream(document) // Defaults to Document if no Element supplied.
      .subscribe((scrollPosition: number): void => {
        const navbarElements = document.getElementsByClassName('navbar-sticky');
        if (navbarElements) {
          for (let i = 0; i < navbarElements.length; i++) {
            const navbar = navbarElements[i];

            if (scrollPosition >= 100) {
              navbar.classList.add("navbar-sticky-moved-up");
            } else {
              navbar.classList.remove("navbar-sticky-moved-up");
            }
            // apply transition
            if (scrollPosition >= 120) {
              navbar.classList.add("navbar-sticky-transitioned");
            } else {
              navbar.classList.remove("navbar-sticky-transitioned");
            }
            // sticky on
            if (scrollPosition >= 140) {
              navbar.classList.add("navbar-sticky-on");
            } else {
              navbar.classList.remove("navbar-sticky-on");
            }

            //navbar.classList.toggle('navbar-sticky-on', window.scrollY > this.zero);
            this.zero = window.scrollY;
          }
        }
      });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }

      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('canvas-open');

      const navbarElements = document.getElementsByClassName('navbar');
      if (navbarElements) {
        for (let i = 0; i < navbarElements.length; i++) {
          const navbar = navbarElements[i];
          navbar.classList.remove('active');
        }
      }

      const navbarIconElements = document.getElementsByClassName('navbar-toggler-icon');
      if (navbarIconElements) {
        for (let i = 0; i < navbarIconElements.length; i++) {
          const navbarIcon = navbarIconElements[i];
          navbarIcon.classList.remove('active');
        }
      }

      const navbarStickyElements = document.getElementsByClassName('navbar-sticky');
        if (navbarStickyElements) {
          for (let i = 0; i < navbarStickyElements.length; i++) {
            const navbarSticky = navbarStickyElements[i];

            navbarSticky.classList.toggle('navbar-sticky-on', window.scrollY > this.zero);
            this.zero = window.scrollY;
          }
        }
    });
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;

    const navbarElements = document.getElementsByClassName('navbar');
    if (navbarElements) {
      for (let i = 0; i < navbarElements.length; i++) {
        const navbar = navbarElements[i];
        navbar.classList.toggle('active');   
      }
    }

    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('canvas-open');

    const navbarIconElements = document.getElementsByClassName('navbar-toggler-icon');
    if (navbarIconElements) {
      for (let i = 0; i < navbarIconElements.length; i++) {
        const navbarIcon = navbarIconElements[i];
        navbarIcon.classList.toggle('active');
      }
    }
  }
}
