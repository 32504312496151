<section class="section work-area bg-overlay overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-6">
                <div class="work-content text-center">
                    <h2 class="text-white">Which Cloud Provider should I use?</h2>
                    <p class="text-white my-3 mt-sm-4 mb-sm-5">Let us guide you through the various providers, from large to small, to see which fits both your requirements and your budget.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="single-work text-center p-3">
                    <div class="work-icon">
                        <img class="avatar-md" src="assets/img/work_thumb_1.png" alt="">
                    </div>
                    <h3 class="text-white py-3">Amazon Web Services</h3>
                    <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?</p>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="single-work text-center p-3">
                    <div class="work-icon">
                        <img class="avatar-md" src="assets/img/work_thumb_2.png" alt="">
                    </div>
                    <h3 class="text-white py-3">Google Cloud Platform</h3>
                    <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?</p>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="single-work text-center p-3">
                    <div class="work-icon">
                        <img class="avatar-md" src="assets/img/work_thumb_3.png" alt="">
                    </div>
                    <h3 class="text-white py-3">Microsoft Azure</h3>
                    <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?</p>
                </div>
            </div>
        </div>
    </div>
</section>