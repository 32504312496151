import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog-service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor(private blogService: BlogService) { }

  ngOnInit(): void {
  }

  getBlogPosts(topN: number) {
    const blogEntries = this.blogService.getBlogEntries(topN);
  
    return blogEntries;
  }
}
