<!-- Tags Widget -->
<div class="accordions widget tags-widget" id="tags-accordion">
    <div class="single-accordion blog-accordion">
        <h5>
            <a role="button" class="collapse show text-uppercase d-block p-3" data-toggle="collapse" routerLink="/" fragment="accordion3">Popular Tags
            </a>
        </h5>
        <!-- Tags Widget Content -->
        <div id="accordion3" class="accordion-content widget-content collapse show" data-parent="#tags-accordion">
            <!-- Tags Widget Items -->
            <div class="widget-content tags-widget-items pt-2">
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Bootstrap</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">IOS</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Android</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Landing Page</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Business</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Fashion</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Media</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Lifestyle</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Wordpress</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Web Design</a>
                <a routerLink="/" class="d-inline-block mt-2 mr-1 px-2 py-1">Mobile App</a>
            </div>
        </div>
    </div>
</div>