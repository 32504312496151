<section class="section testimonial-area ptb_100">
    <div class="container text-center">
        <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-10 col-lg-8">
                <div class="testimonials owl-carousel">
                    <!-- Single Testimonial -->
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatar-1.png" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">John Doe</h3>
                        <!-- Client Address -->
                        <h5 class="client-address fw-4">Los Angeles, California</h5>
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="far fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Testimonial -->
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatar-2.png" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Jassica William</h3>
                        <!-- Client Address -->
                        <h5 class="client-address fw-4">Los Angeles, California</h5>
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Testimonial -->
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatar-3.png" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Johnson Smith</h3>
                        <!-- Client Address -->
                        <h5 class="client-address fw-4">Los Angeles, California</h5>
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="far fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>