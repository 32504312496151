<div class="blog blog-right">
    <div class="main">
        <scroll-up></scroll-up>
        <app-navbar [whiteLogo]="true"></app-navbar>
        <app-breadcrumb title="Blog" currentPage="Blog" ></app-breadcrumb>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="row">
                            <ng-container *ngFor="let blogPost of getBlogPosts(6)">
                                <div class="col-12 col-md-6">
                                    <app-blog-entry [blogEntry]="blogPost"></app-blog-entry>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <app-blog-sidebar></app-blog-sidebar>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-two></app-footer-two>
    </div>
</div>