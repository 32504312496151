<footer class="section inner-footer bg-gray ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Footer Items -->
                <div class="footer-items text-center">
                    <!-- Logo -->
                    <a class="navbar-brand" routerLink="/">
                        <img class="logo" src="assets/img/logo.png" alt="">
                    </a>
                    <p class="mt-2 mb-3">Technology Solutions For Your Business</p>
                    <ul class="d-flex flex-column flex-sm-row justify-content-between">
                        <li class="py-2"><a routerLink="/terms-conditions">Terms &amp; Conditions</a></li>
                        <li class="py-2"><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                    <!-- Copyright Area -->
                    <div class="copyright-area border-0 pt-3">
                        <p>&copy; 2020 <a href="https://www.pymatech.com/">PymaTech</a> All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>