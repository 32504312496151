import { Component, OnInit, Input } from '@angular/core';
import { BlogEntry } from 'src/app/models/BlogEntry';

@Component({
  selector: 'app-blog-entry[blogEntry]',
  templateUrl: './blog-entry.component.html',
  styleUrls: ['./blog-entry.component.css']
})
export class BlogEntryComponent implements OnInit {

  @Input()
  public blogEntry: BlogEntry;

  constructor() {
  }

  ngOnInit(): void {
  }

  showBlogsByAuthor(author: string): boolean{
    console.log(`showBlogsByAuthor: ${author}`);
    return true;
  }

  formatDate(date: Date) {
    if (date)
    {
      return date.toLocaleString('default', { weekday:'long',year:'numeric',month:'long',day:'numeric' });
    }
    return '';
  }
}
