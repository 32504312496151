<aside class="sidebar">
    <!-- Single Widget -->
    <div class="single-widget">
        <!-- Search Widget -->
        <div class="widget-content search-widget">
            <form action="#">
                <input type="text" placeholder="Enter your keywords">
            </form>
        </div>
    </div>
    <!-- Single Widget -->
    <div class="single-widget">
        <!-- Category Widget -->
        <app-blog-categories></app-blog-categories>
    </div>
    <!-- Single Widget -->
    <div class="single-widget">
        <app-popular-posts></app-popular-posts>
    </div>
    <!-- Single Widget -->
    <div class="single-widget">
        <app-popular-tags></app-popular-tags>
    </div>
</aside>