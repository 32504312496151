<!-- Post Widget -->
<div class="accordions widget post-widget" id="post-accordion">
    <div class="single-accordion">
        <h5>
            <a role="button" class="collapse show text-uppercase d-block p-3" data-toggle="collapse" routerLink="/" fragment="accordion2">Popular Post
            </a>
        </h5>
        <!-- Post Widget Content -->
        <div id="accordion2" class="accordion-content widget-content collapse show" data-parent="#post-accordion">
            <!-- Post Widget Items -->
            <ul class="widget-items">
                <li>
                    <a routerLink="/" class="single-post media p-3">
                        <!-- Post Thumb -->
                        <div class="post-thumb avatar-md">
                            <img class="align-self-center" src="assets/img/avatar-1.png" alt="">
                        </div>
                        <div class="post-content media-body pl-3">
                            <p class="post-date mb-2">05 Feb, 2018</p>
                            <h6>Promote Your Apps With PymaTech</h6>
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/" class="single-post media p-3">
                        <!-- Post Thumb -->
                        <div class="post-thumb avatar-md">
                            <img class="align-self-center" src="assets/img/avatar-2.png" alt="">
                        </div>
                        <div class="post-content media-body pl-3">
                            <p class="post-date mb-2">09 Apr, 2018</p>
                            <h6>Sell Your Product Like Never Before</h6>
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/" class="single-post media p-3">
                        <!-- Post Thumb -->
                        <div class="post-thumb avatar-md">
                            <img class="align-self-center" src="assets/img/avatar-3.png" alt="">
                        </div>
                        <div class="post-content media-body pl-3">
                            <p class="post-date mb-2">13 Jul, 2018</p>
                            <h6>Built For Passionate People</h6>
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/" class="single-post media p-3">
                        <!-- Post Thumb -->
                        <div class="post-thumb avatar-md">
                            <img class="align-self-center" src="assets/img/avatar-4.png" alt="">
                        </div>
                        <div class="post-content media-body pl-3">
                            <p class="post-date mb-2">03 Oct, 2018</p>
                            <h6>Get Unlimited Offers &amp; Plans</h6>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>